import { defineAbility } from '@casl/ability';
import { UserRole } from 'src/apiClient';
import pages from 'src/router/routes';

/**
 * Define user abilities based on their user type.
 * @param userType - The type of the user, e.g., 'admin' or 'user'.
 * @returns {Ability} - An instance of the defined abilities for the user.
 */
export default function defineAbilityFor(userType: string) {
  return defineAbility((can, cannot) => {
    if (userType === UserRole.LS_ADMIN) {
      //###### pages ######
      //The below defines top level pages the user has access to
      Object.values(abilityPages).forEach((page) => {
        can('visit', page);
      });

      //###### features ######
      //This will be sub-features that exist (it can be nested)
    } else if (userType === UserRole.LS_USER) {
      //###### pages ######
      //The below defines top level pages the user has access to
      can('visit', abilityPages.DASHBOARD);
      can('visit', abilityPages.NONE);
      can('visit', abilityPages.BUILDINGS);
      can('visit', abilityPages.CLAIMS);
      can('visit', abilityPages.CLAIMS_NO);
      can('visit', abilityPages.CLIENT);
      can('visit', abilityPages.AUTH);

      //###### features ######
    } else if (userType === UserRole.CLIENT_ADMIN) {
      //###### pages ######
      //The below defines top level pages the user has access to
      can('visit', abilityPages.DASHBOARD);
      can('visit', abilityPages.BUILDINGS);
      can('visit', abilityPages.CLAIMS);
      can('visit', abilityPages.CLAIMS_NO);
      can('visit', abilityPages.NONE);
      cannot('visit', abilityPages.CLIENT);
      can('visit', abilityPages.AUTH);

      //###### features ######
      //This will be sub-features that exist (it can be nested)
    } else if (userType === UserRole.CLIENT_USER) {
      //###### pages ######
      //The below defines top level pages the user has access to
      can('visit', abilityPages.DASHBOARD);
      can('visit', abilityPages.BUILDINGS);
      can('visit', abilityPages.CLAIMS);
      can('visit', abilityPages.CLAIMS_NO);
      can('visit', abilityPages.NONE);
      cannot('visit', abilityPages.CLIENT);
      can('visit', abilityPages.AUTH);

      //###### features ######
    } else if (userType === UserRole.CLIENT_READ_ONLY) {
      //###### pages ######
      //The below defines top level pages the user has access to
      can('visit', abilityPages.DASHBOARD);
      can('visit', abilityPages.BUILDINGS);
      can('visit', abilityPages.CLAIMS);
      can('visit', abilityPages.CLAIMS_NO);
      can('visit', abilityPages.NONE);
      cannot('visit', abilityPages.CLIENT);
      can('visit', abilityPages.AUTH);

      //###### features ######
    } else if (userType === 'guest') {
      //###### pages ######
      //The below defines top level pages the user has access to
      cannot('visit', abilityPages.DASHBOARD);
      can('visit', abilityPages.NONE);
      can('visit', abilityPages.AUTH);

      //###### features ######
    }
    //Add other roles here
  });
}

export const abilityPages = {
  CLIENT: pages.client.name,
  DASHBOARD: pages.dashboard.name,
  CLIENTS_MANAGEMENT: pages.clientsManagement.name,
  BUILDINGS: pages.client.buildings.name,
  CLAIMS: pages.client.buildings.claims.name,
  CLAIMS_NO: pages.client.buildings.claims.claimNo.name,
  NONE: '',
  AUTH: 'auth/*',
};

export const enum ABILITY_TYPES {
  VISIT = 'visit',
}
