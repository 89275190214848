import {
  CreateBuildingCoverMultipleData,
  CreateBuildingCoverMultipleResponse,
  GetAllBuildingsData,
  GetAllBuildingsResponse,
  GetAllBuildingsTableData,
  GetAllBuildingsTableResponse,
  GetBuildingCoverMultiplesData,
  GetBuildingCoverMultiplesResponse,
  GetBuildingNamesData,
  GetBuildingNamesResponse,
} from 'src/apiClient/types.gen';
import { ENDPOINT } from '../config/apiTags';
import { baseApi } from './baseApi.service';

const buildingsApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    // ############################## GET ALL Buildings ###################################
    getAllBuildings: builder.query<GetAllBuildingsResponse, GetAllBuildingsData['query']>({
      query: (query) => ({
        url: ENDPOINT.BUILDINGS,
        method: 'GET',
        params: query,
      }),
    }),
    getBuildingCoverMultiples: builder.query<
      GetBuildingCoverMultiplesResponse,
      GetBuildingCoverMultiplesData['query']
    >({
      query: (query) => ({
        url: `${ENDPOINT.BUILDINGS}/cover-multiples`,
        method: 'GET',
        params: query,
      }),
      transformResponse: (response: GetBuildingCoverMultiplesResponse) => response,
    }),
    getAllBuildingsTable: builder.query<
      GetAllBuildingsTableResponse,
      GetAllBuildingsTableData['query']
    >({
      query: (query) => ({
        url: `${ENDPOINT.BUILDINGS}/buildingTable`,
        method: 'GET',
        params: query,
      }),
      transformResponse: (response: GetAllBuildingsTableResponse) => response,
    }),
    getBuildingNames: builder.query<GetBuildingNamesResponse, GetBuildingNamesData['query']>({
      query: (query) => ({
        url: `${ENDPOINT.BUILDINGS}/building-names`,
        method: 'GET',
        params: query,
      }),
      transformResponse: (response: GetBuildingNamesResponse) => response,
    }),
    updateBuildingCoverMultiples: builder.mutation<
      CreateBuildingCoverMultipleResponse,
      CreateBuildingCoverMultipleData['path'] & CreateBuildingCoverMultipleData['body']
    >({
      query: (data) => ({
        url: `${ENDPOINT.BUILDINGS}/${data.building_id}/cover-multiples`,
        method: 'POST',
        body: data,
      }),
    }),
  }),
});

export const {
  useGetAllBuildingsQuery,
  useLazyGetAllBuildingsQuery,
  useGetAllBuildingsTableQuery,
  useLazyGetAllBuildingsTableQuery,
  useGetBuildingNamesQuery,
  useLazyGetBuildingNamesQuery,
  useGetBuildingCoverMultiplesQuery,
  useUpdateBuildingCoverMultiplesMutation,
} = buildingsApi;
