import { FC, Suspense, lazy } from 'react';
import { RouteObject } from 'react-router';
import { Navigate } from 'react-router-dom';
import AuthGuard from 'src/Guards/authGuard/AuthGuard';
import SuspenseLoader from 'src/components/suspenseLoader';
import AppBarLayout from 'src/layouts/AppBarLayout';
import BaseLayout from 'src/layouts/BaseLayout';
import Unverified from 'src/pages/Fallbacks/Status/Unverified/Unverified';
import pages from './routes';
// import SidebarLayout from 'src/layouts/SidebarLayout';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Loader = (Component: FC) => (props: any) => (
  <Suspense fallback={<SuspenseLoader />}>
    <Component {...props} />
  </Suspense>
);

// Pages
// const Home = Loader(lazy(() => import('src/pages/Home/Home')));
const Client = Loader(lazy(() => import('src/pages/Client/Client')));
const Buildings = Loader(lazy(() => import('src/pages/Buildings/Buildings')));
const Claims = Loader(lazy(() => import('src/pages/Claims/Claims')));
const ClaimNo = Loader(lazy(() => import('src/pages/ClaimNo/ClaimNo')));
const Register = Loader(lazy(() => import('src/pages/Register/Register')));
const Login = Loader(lazy(() => import('src/pages/Login/Login')));

/* new */
const Dashboard = Loader(lazy(() => import('src/pages/Home/Home')));
const ClientsManagement = Loader(
  lazy(() => import('src/pages/ClientsManagement/ClientsManagement'))
);
const ClientOverview = Loader(lazy(() => import('src/pages/ClientOverview/ClientOverview')));
const BuildingOverview = Loader(lazy(() => import('src/pages/BuildingOverview/BuildingOverview')));

// Status
const Status404 = Loader(lazy(() => import('src/pages/Fallbacks/Status/Status404/Status404')));
const Status500 = Loader(lazy(() => import('src/pages/Fallbacks/Status/Status500/Status500')));
const StatusComingSoon = Loader(
  lazy(() => import('src/pages/Fallbacks/Status/ComingSoon/ComingSoon'))
);
const StatusMaintenance = Loader(
  lazy(() => import('src/pages/Fallbacks/Status/Maintenance/Maintenance'))
);
const StatusSuccess = Loader(lazy(() => import('src/pages/Fallbacks/Status/Success/Success')));
const StatusFailure = Loader(lazy(() => import('src/pages/Fallbacks/Status/Failure/Failure')));
const StatusCancel = Loader(lazy(() => import('src/pages/Fallbacks/Status/Cancel/Cancel')));

const routes: RouteObject[] = [
  {
    path: '',
    element: <BaseLayout />,
    /**
     * All children within this element will NOT have a Sidebar and top Navbar
     * All children within this element does not need to be authenticated to access
     */
    children: [
      //#region Base
      {
        //Navigate to home when base routed to base path
        path: '/',
        element: <Navigate to={pages.dashboard.path} replace />,
      },
      //#endregion Base
      //#region Auth
      {
        //All authentication routes
        //No navbars are shown as the user is not logged in
        path: pages.auth.root,
        children: [
          {
            path: '',
            element: <Login />,
          },
          {
            path: pages.auth.login.name,
            element: <Login />,
          },
          {
            path: pages.auth.register.name,
            element: <Register />,
          },
        ],
      },
      //#endregion Auth
      //#region Status
      {
        //All status routes
        path: pages.status.root,
        children: [
          {
            path: '',
            element: <Navigate to="404" replace />,
          },
          {
            path: pages.status.unverified.name,
            element: <Unverified />,
          },
          {
            path: pages.status.status404.name,
            element: <Status404 />,
          },
          {
            path: pages.status.status500.name,

            element: <Status500 />,
          },
          {
            path: pages.status.statusMaintenance.name,
            element: <StatusMaintenance />,
          },
          {
            path: pages.status.statusComingSoon.name,
            element: <StatusComingSoon />,
          },
          //TODO: Maybe make paths that shows status within the sidebars as well
          {
            path: pages.status.statusSuccess.name,
            element: <StatusSuccess />,
          },
          {
            path: pages.status.statusFailure.name,
            element: <StatusFailure />,
          },
          {
            path: pages.status.statusCancel.name,
            element: <StatusCancel />,
          },
        ],
      },
      //#endregion Status
      //#region NotFound
      {
        path: '*',
        element: <Status404 />,
      },
      //#endregion NotFound
    ],
  },
  {
    path: '',
    element: (
      /**
       * All children with this element will have a Sidebar and top Navbar
       * AuthGuard checks that the user is logged in before granting access to its children pages
       */
      <AuthGuard>
        {/* <SidebarLayout /> */}
        <AppBarLayout />
      </AuthGuard>
    ),
    children: [
      //#region Base
      {
        path: '',
        element: <Navigate to={pages.dashboard.name} replace />,
      },
      //#endregion Base
      //#region Home
      {
        path: pages.dashboard.path,
        element: <Dashboard />,
      },
      //#endregion Home
      //#region Transaction Codes
      {
        path: pages.clientsManagement.path,
        children: [
          {
            path: '',
            element: <ClientsManagement />,
          },
          {
            path: pages.clientsManagement.clientOverview.path,
            // element: <ClientOverview />,
            children: [
              {
                path: '',
                element: <ClientOverview />,
              },
              {
                path: pages.clientsManagement.clientOverview.buildingsManagement.buildingOverview
                  .path,
                element: <BuildingOverview />,
              },
            ],
          },
        ],
      },
      //#endregion Transaction Codes
      //#region Claims
      {
        path: pages.client.name,
        children: [
          {
            path: '',
            element: <Client />,
          },

          {
            path: pages.client.buildings.path,
            element: <Buildings />,
          },
          {
            path: pages.client.buildings.claims.path,
            children: [
              {
                path: '',
                element: <Claims />,
              },
            ],
          },
          {
            path: pages.client.buildings.claims.claimNo.path,
            children: [
              {
                path: '',
                element: <ClaimNo />,
              },
            ],
          },
        ],
      },
      //#endregion Claims
    ],
  },
];

export default routes;
